import { AfterViewInit, Component, Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: 'autofocus',

})
export class AutoFocusComponent implements AfterViewInit {
  constructor(private elementRef:ElementRef) { }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.focus();
  }


}
