import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import{HttpClient} from '@angular/common/http'
import { RightPanelService } from '../HttpClientServices/right-panel.service';
import { NONE_TYPE } from '@angular/compiler';
@Component({
  selector: 'app-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class RightPanelComponent implements OnInit {
  wcmContent:any;
  constructor(private http:HttpClient,private rightAPI:RightPanelService) { }

  ngOnInit(): void {
    this.getRightPanel();
  }
    getRightPanel(){
    this.rightAPI.rightPanelAPI().subscribe(
      (res:any)=>{this.wcmContent=res},
      (err: any)=>console.log("Failed to fetch data"),
        ()=>{}
    );
  }

}
