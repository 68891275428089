import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  kemper=environment.KEMPER_COM;
  logo=environment.KEMPER_LOGO;
  customerLogin=environment.CUSTOMERLOGIN;
  contactUs=environment.CONTACTUS;
  constructor() { }

  ngOnInit(): void {
  }

}
