export const environment = {
  production: true,
  KEMPER_COM: 'https://www.kemper.com',
  KEMPER_LOGO: './assets/Kemper_Logo_small.png',
  CUSTOMERLOGIN: 'https://www.kemper.com/wps/portal/Kemper/Home/InformationCenter/CustomerLogin/',
  CONTACTUS: 'https://www.kemper.com/wps/portal/Kemper/Home/AboutKemper/ContactUs/',
  FORGOT_ID: 'https://agent.kemper.com/auto/kidm/unsecure/userForgotUserId?TARGET=',
  FORGOT_PASSW: 'https://agent.kemper.com/auto/kidm/unsecure/userForgotPassword?TARGET=',
  SIGNUP: 'https://agent.kemper.com/auto/kidm/unsecure/selfReg?TARGET=',
  QUICKPAY: 'https://myaccount.kemper.com/us/home/index.cfm/go/payments.home/',
  RIGHT_PANEL_API: 'https://www.agentinside.com/RESTWCMContentPortalService/rest/RESTWCMService/query?wcmpath=/KemperAgent/Login/LoginPageNonStandardContent&pt=PT_LOGIN',
  AM_INTERNAL_AWS_HOST: 'https://frigappp0.prd.aws.kemperi.com/',
  AM_INTERNAL_BHM_HOST: 'https://frigp0.kemperi.com/',
  AM_EXTERNAL_AWS_HOST: 'https://signin.kemper.com/',
  AM_EXTERNAL_BHM_HOST: 'https://signinb.kemper.com/',
  DEFAULT_SERVICE: 'fr_authentication',
  DEFAULT_REALM: '/AEV',
  GOTO: '/openam/console',
  KEMPER_TERMS: 'https://www.kemper.com/terms-of-use',
  KEMPER_PRIVACY: 'https://www.kemper.com/privacy-policy',
  CA_POLICY: 'https://www.kemper.com/ccpa-notice',
  AM_HEADERS: {
    'Content-Type': 'application/json',
    'Accept-API-Version': 'resource=2.0, protocol=1.0',
    'Cache-Control': 'no-cache',
  }
};
