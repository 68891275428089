import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
  logo=environment.KEMPER_LOGO;
  kemper=environment.KEMPER_COM
  username:any
  
  constructor(private route: ActivatedRoute,
    private router: Router) { }
  
  ngOnInit(): void {
    this.route.queryParams.subscribe(params=>{
      this.username=params.username;
    })
    }

}
