import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  kemper = environment.KEMPER_COM;
  caPolicy = environment.CA_POLICY;
  privacy = environment.KEMPER_PRIVACY;
  terms = environment.KEMPER_TERMS;
  constructor() { }

  ngOnInit(): void {
  }

}
